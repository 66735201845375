<template>
    <v-container fluid>
        <v-card :loading="loading">
            <Page-Header
                :title="$t('fiscalYear.fiscalYears')"
                icon="mdi-calendar-multiple"
            >
                <template v-slot:buttons>
                    <v-btn
                        :loading="loading"
                        color="primary darken-1"
                        @click.native="dialog = true"
                    >
                        <v-icon>mdi-plus</v-icon>
                        {{ $t("add") }}
                    </v-btn>
                </template>
            </Page-Header>
            <!--Save popup-->
            <v-dialog v-model="dialog" persistent max-width="600px">
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-card>
                        <v-toolbar color="primary darken-1" dark>
                            <v-card-title>
                                <span>{{
                                    (editedIndex === -1
                                        ? $t("add")
                                        : $t("edit")) +
                                        " " +
                                        $t("fiscalYear.fiscalYear")
                                }}</span>
                            </v-card-title>
                        </v-toolbar>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" md="12">
                                        <v-text-field
                                            hide-details
                                            outlined
                                            dense
                                            persistent-hint
                                            v-model="editedItem.title"
                                            :rules="rules"
                                            :label="$t('fiscalYear.title')"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <template>
                                            <div>
                                                <v-menu
                                                    ref="menu"
                                                    v-model="menu"
                                                    :close-on-content-click="
                                                        false
                                                    "
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="auto"
                                                >
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs
                                                        }"
                                                    >
                                                        <v-text-field
                                                            hide-details
                                                            outlined
                                                            dense
                                                            :rules="rules"
                                                            v-model="
                                                                editedItem.fromDate
                                                            "
                                                            :label="
                                                                $t(
                                                                    'reports.fromDate'
                                                                )
                                                            "
                                                            readonly
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                        v-model="
                                                            editedItem.fromDate
                                                        "
                                                        :active-picker.sync="
                                                            activePicker
                                                        "
                                                        @change="saveDate"
                                                    ></v-date-picker>
                                                </v-menu>
                                            </div>
                                        </template>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <template>
                                            <div>
                                                <v-menu
                                                    ref="menu2"
                                                    v-model="menu2"
                                                    :close-on-content-click="
                                                        false
                                                    "
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="auto"
                                                >
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs
                                                        }"
                                                    >
                                                        <v-text-field
                                                            hide-details
                                                            outlined
                                                            dense
                                                            :rules="rules"
                                                            v-model="
                                                                editedItem.toDate
                                                            "
                                                            :label="
                                                                $t(
                                                                    'reports.toDate'
                                                                )
                                                            "
                                                            readonly
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                        v-model="
                                                            editedItem.toDate
                                                        "
                                                        :active-picker.sync="
                                                            activePicker2
                                                        "
                                                        @change="saveDate2"
                                                    ></v-date-picker>
                                                </v-menu>
                                            </div>
                                        </template>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <v-textarea
                                            outlined
                                            hide-details
                                            dense
                                            rows="1"
                                            v-model="editedItem.notes"
                                            :label="$t('fiscalYear.notes')"
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn
                                color="primary darken-1 white--text"
                                @click="save"
                                :disabled="!valid"
                                :min-width="100"
                                :loading="loading"
                            >
                                <v-icon>mdi-content-save-outline</v-icon>
                                {{ $t("save") }}
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn
                                :loading="loading"
                                text
                                color="red"
                                @click="close"
                            >
                                {{ $t("cancel") }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-dialog>
            <template>
                <v-row class="pa-10" justify="center">
                    <v-card
                        v-for="fiscalYear in fiscalYears"
                        :key="fiscalYear.fiscalYearGuid"
                        class="mb-10 mx-5"
                        max-width="250"
                        min-width="250"
                        :loading="loading"
                        outlined
                        shaped
                        rounded
                    >
                        <v-toolbar
                            elevation="0"
                            outlined
                            :color="$vuetify.theme.dark ? '#2e5085' : '#dce9fc'"
                            :class="
                                $vuetify.theme.dark
                                    ? 'white--text'
                                    : 'black--text'
                            "
                        >
                            <h3>
                                <v-icon
                                    size="16"
                                    :color="
                                        fiscalYear.isActive ? 'green' : 'red'
                                    "
                                    >mdi-circle</v-icon
                                >
                                {{ fiscalYear.title }}
                            </h3>
                        </v-toolbar>
                        <v-list-item three-line>
                            <v-list-item-content>
                                <v-list-item-subtitle>
                                    <v-row>
                                        <v-col cols="12">
                                            <span>
                                                {{
                                                    fiscalYear.isActive
                                                        ? $t(
                                                              "fiscalYear.active"
                                                          )
                                                        : $t(
                                                              "fiscalYear.inactive"
                                                          )
                                                }}
                                            </span></v-col
                                        >
                                        <v-col cols="12">
                                            <span>
                                                {{ $t("fiscalYear.fromDate") }}:
                                                {{ fiscalYear.fromDate }}
                                            </span></v-col
                                        >
                                        <v-col cols="12"
                                            ><span>
                                                {{ $t("fiscalYear.toDate") }}:
                                                {{ fiscalYear.toDate }}
                                            </span></v-col
                                        >
                                    </v-row>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-btn
                                v-if="isInRole(94)"
                                color="primary darken-1"
                                dark
                                :loading="loading"
                                class="mx-2 my-1"
                                @click="editItem(fiscalYear)"
                            >
                                {{ $t("edit") }}
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn
                                v-if="isInRole(95)"
                                text
                                :loading="loading"
                                color="red"
                                class="mx-2 my-1"
                                @click="deleteItem(fiscalYear)"
                            >
                                {{ $t("delete") }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-row>
                <!--Delete popup-->
                <confirm-dialog
                    :openDialog="dialogDelete"
                    :onClicked="deleteItemConfirm"
                    :onClose="closeDelete"
                    toolBarColor="red darken-2"
                ></confirm-dialog>
            </template>
        </v-card>
    </v-container>
</template>
<script>
import axios from "axios";
import moment from "moment";
import ConfirmDialog from "../../components/ConfirmDialog.vue";
import PageHeader from "../../components/PageHeader.vue";

export default {
    components: {
        ConfirmDialog,
        PageHeader
    },
    data() {
        return {
            date: null,
            date2: null,
            activePicker: null,
            menu: false,
            activePicker2: null,
            menu2: false,
            valid: true,
            loading: true,
            dialog: false,
            dialogDelete: false,
            editedIndex: -1,
            fiscalYears: [],
            editedItem: {
                fiscalYearGuid: "00000000-0000-0000-0000-000000000000",
                title: null,
                fromDate: new Date()
                    .toJSON()
                    .slice(0, 10)
                    .replace(/-/g, "-"),
                toDate: new Date()
                    .toJSON()
                    .slice(0, 10)
                    .replace(/-/g, "-"),
                isActive: false,
                notes: null
            },
            defaultItem: {
                fiscalYearGuid: "00000000-0000-0000-0000-000000000000",
                title: null,
                fromDate: new Date()
                    .toJSON()
                    .slice(0, 10)
                    .replace(/-/g, "-"),
                toDate: new Date()
                    .toJSON()
                    .slice(0, 10)
                    .replace(/-/g, "-"),
                isActive: false,
                notes: null
            },

            rules: [value => !!value || this.$t("required")]
        };
    },
    created() {
        if (this.isInRole(92)) {
            this.loading = true;
            this.getData();
        } else this.redirectUnauthorizedUsers();
    },
    watch: {
        dialog(val) {
            val || this.close();
        },
        dialogDelete(val) {
            val || this.closeDelete();
        }
    },
    methods: {
        editItem(item) {
            this.editedIndex = this.fiscalYears.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialog = true;
        },
        deleteItem(item) {
            this.editedIndex = this.fiscalYears.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },
        deleteItemConfirm() {
            var deleteItem = this.fiscalYears[this.editedIndex];
            axios
                .delete("fiscalYears/Delete?id=" + deleteItem.fiscalYearGuid)
                .then(response => {
                    if (response.data.status == "Successful") {
                        this.$toast.success(
                            this.$t("operationAccomplishedSuccessfully")
                        );
                    } else {
                        this.$toast.error(
                            this.$t("error." + response.data.message)
                        );
                    }
                    this.getData();
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    this.getData();
                    console.log(e);
                });

            this.closeDelete();
        },
        saveDate(date) {
            this.$refs.menu.save(date);
        },
        saveDate2(date) {
            this.$refs.menu2.save(date);
        },
        close() {
            this.dialog = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
            this.$refs.form.reset();
            this.$refs.form.resetValidation();
        },
        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        getData() {
            try {
                this.loading = true;
                axios
                    .get("FiscalYears/Get")
                    .then(response => {
                        this.fiscalYears = response.data.data;
                        this.fiscalYears.forEach(item => {
                            item.fromDate = moment(item.fromDate).format(
                                "yyyy-MM-DD"
                            );
                            item.toDate = moment(item.toDate).format(
                                "yyyy-MM-DD"
                            );
                        });
                    })
                    .catch(e => {
                        this.$toast.error(
                            this.$t("AnErrorOccurredDuringTheProcess")
                        );
                        console.log(e);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            } catch (error) {
                console.log(error);
            }
        },
        save() {
            if (this.$refs.form.validate()) {
                if (this.editedIndex == -1) {
                    this.editedItem.fiscalYearGuid =
                        "00000000-0000-0000-0000-000000000000";
                }
                axios
                    .post("FiscalYears/Save", this.editedItem)
                    .then(response => {
                        if (response.data.status == "Successful") {
                            this.$toast.success(
                                this.$t("operationAccomplishedSuccessfully")
                            );
                            this.close();
                        } else {
                            this.$toast.error(
                                this.$t("error." + response.data.message)
                            );
                        }
                        this.getData();
                    })
                    .catch(e => {
                        this.$toast.error(
                            this.$t("AnErrorOccurredDuringTheProcess")
                        );
                        this.getData();
                        console.log(e);
                    });
            }
        }
    }
};
</script>
