var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{attrs:{"loading":_vm.loading}},[_c('Page-Header',{attrs:{"title":_vm.$t('fiscalYear.fiscalYears'),"icon":"mdi-calendar-multiple"},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('v-btn',{attrs:{"loading":_vm.loading,"color":"primary darken-1"},nativeOn:{"click":function($event){_vm.dialog = true}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t("add"))+" ")],1)]},proxy:true}])}),_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary darken-1","dark":""}},[_c('v-card-title',[_c('span',[_vm._v(_vm._s((_vm.editedIndex === -1 ? _vm.$t("add") : _vm.$t("edit")) + " " + _vm.$t("fiscalYear.fiscalYear")))])])],1),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-text-field',{attrs:{"hide-details":"","outlined":"","dense":"","persistent-hint":"","rules":_vm.rules,"label":_vm.$t('fiscalYear.title')},model:{value:(_vm.editedItem.title),callback:function ($$v) {_vm.$set(_vm.editedItem, "title", $$v)},expression:"editedItem.title"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[[_c('div',[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                    var on = ref.on;
                                                    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"hide-details":"","outlined":"","dense":"","rules":_vm.rules,"label":_vm.$t(
                                                                'reports.fromDate'
                                                            ),"readonly":""},model:{value:(
                                                            _vm.editedItem.fromDate
                                                        ),callback:function ($$v) {_vm.$set(_vm.editedItem, "fromDate", $$v)},expression:"\n                                                            editedItem.fromDate\n                                                        "}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"active-picker":_vm.activePicker},on:{"update:activePicker":function($event){_vm.activePicker=$event},"update:active-picker":function($event){_vm.activePicker=$event},"change":_vm.saveDate},model:{value:(
                                                        _vm.editedItem.fromDate
                                                    ),callback:function ($$v) {_vm.$set(_vm.editedItem, "fromDate", $$v)},expression:"\n                                                        editedItem.fromDate\n                                                    "}})],1)],1)]],2),_c('v-col',{attrs:{"cols":"12","md":"6"}},[[_c('div',[_c('v-menu',{ref:"menu2",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                    var on = ref.on;
                                                    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"hide-details":"","outlined":"","dense":"","rules":_vm.rules,"label":_vm.$t(
                                                                'reports.toDate'
                                                            ),"readonly":""},model:{value:(
                                                            _vm.editedItem.toDate
                                                        ),callback:function ($$v) {_vm.$set(_vm.editedItem, "toDate", $$v)},expression:"\n                                                            editedItem.toDate\n                                                        "}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"active-picker":_vm.activePicker2},on:{"update:activePicker":function($event){_vm.activePicker2=$event},"update:active-picker":function($event){_vm.activePicker2=$event},"change":_vm.saveDate2},model:{value:(
                                                        _vm.editedItem.toDate
                                                    ),callback:function ($$v) {_vm.$set(_vm.editedItem, "toDate", $$v)},expression:"\n                                                        editedItem.toDate\n                                                    "}})],1)],1)]],2),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-textarea',{attrs:{"outlined":"","hide-details":"","dense":"","rows":"1","label":_vm.$t('fiscalYear.notes')},model:{value:(_vm.editedItem.notes),callback:function ($$v) {_vm.$set(_vm.editedItem, "notes", $$v)},expression:"editedItem.notes"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary darken-1 white--text","disabled":!_vm.valid,"min-width":100,"loading":_vm.loading},on:{"click":_vm.save}},[_c('v-icon',[_vm._v("mdi-content-save-outline")]),_vm._v(" "+_vm._s(_vm.$t("save"))+" ")],1),_c('v-spacer'),_c('v-btn',{attrs:{"loading":_vm.loading,"text":"","color":"red"},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")])],1)],1)],1)],1),[_c('v-row',{staticClass:"pa-10",attrs:{"justify":"center"}},_vm._l((_vm.fiscalYears),function(fiscalYear){return _c('v-card',{key:fiscalYear.fiscalYearGuid,staticClass:"mb-10 mx-5",attrs:{"max-width":"250","min-width":"250","loading":_vm.loading,"outlined":"","shaped":"","rounded":""}},[_c('v-toolbar',{class:_vm.$vuetify.theme.dark
                                ? 'white--text'
                                : 'black--text',attrs:{"elevation":"0","outlined":"","color":_vm.$vuetify.theme.dark ? '#2e5085' : '#dce9fc'}},[_c('h3',[_c('v-icon',{attrs:{"size":"16","color":fiscalYear.isActive ? 'green' : 'red'}},[_vm._v("mdi-circle")]),_vm._v(" "+_vm._s(fiscalYear.title)+" ")],1)]),_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('v-list-item-subtitle',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('span',[_vm._v(" "+_vm._s(fiscalYear.isActive ? _vm.$t( "fiscalYear.active" ) : _vm.$t( "fiscalYear.inactive" ))+" ")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("fiscalYear.fromDate"))+": "+_vm._s(fiscalYear.fromDate)+" ")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("fiscalYear.toDate"))+": "+_vm._s(fiscalYear.toDate)+" ")])])],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[(_vm.isInRole(94))?_c('v-btn',{staticClass:"mx-2 my-1",attrs:{"color":"primary darken-1","dark":"","loading":_vm.loading},on:{"click":function($event){return _vm.editItem(fiscalYear)}}},[_vm._v(" "+_vm._s(_vm.$t("edit"))+" ")]):_vm._e(),_c('v-spacer'),(_vm.isInRole(95))?_c('v-btn',{staticClass:"mx-2 my-1",attrs:{"text":"","loading":_vm.loading,"color":"red"},on:{"click":function($event){return _vm.deleteItem(fiscalYear)}}},[_vm._v(" "+_vm._s(_vm.$t("delete"))+" ")]):_vm._e()],1)],1)}),1),_c('confirm-dialog',{attrs:{"openDialog":_vm.dialogDelete,"onClicked":_vm.deleteItemConfirm,"onClose":_vm.closeDelete,"toolBarColor":"red darken-2"}})]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }